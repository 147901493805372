import { Injectable } from '@angular/core';
import { CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '../../../node_modules/@angular/router';
import { UserInfo } from '../model/userInfo';

@Injectable()
export class LoginGaurdService implements CanActivateChild {

  constructor(private userInfo: UserInfo, private route: Router) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

  if (this.userInfo.getAccessToken() === null && state.url.indexOf('admin') !== -1) {
      this.route.navigate(['admin']);
    } else if (this.userInfo.getAccessToken() !== null && this.userInfo.getUserType() === 'USER' && state.url.indexOf('admin') !== -1) {
      this.route.navigate(['admin']);
    }
    if (this.userInfo.getAccessToken() === null && (state.url.indexOf('user/change-password') !== -1 || state.url.indexOf('user/profile') !== -1)) {
      this.route.navigate(['user']);
    } else if (this.userInfo.getAccessToken() !== null && this.userInfo.getUserType() === 'ADMIN' && (state.url.indexOf('user/change-password') !== -1 || state.url.indexOf('user/profile') !== -1)) {
      this.route.navigate(['user']);
    }
    if (this.userInfo.getAccessToken() === null && (state.url.indexOf('user/matrimonial/myMatrimonial') !== -1)) {
      this.route.navigate(['user/login']);
    } else if (this.userInfo.getAccessToken() !== null && this.userInfo.getUserType() === 'ADMIN' && (state.url.indexOf('user/matrimonial/myMatrimonial') !== -1)) {
      this.route.navigate(['user/login']);
    }
    // if (this.userInfo.getAccessToken() === null && (state.url.indexOf('user/business/bProfile') !== -1)) {
    //   this.route.navigate(['user/business/bProfile']);
    // } else if (this.userInfo.getAccessToken() !== null && this.userInfo.getUserType() === 'ADMIN' && (state.url.indexOf('user/business/bProfile') !== -1)) {
    //   this.route.navigate(['user/business/bProfile']);
    // }
    // if (this.userInfo.getAccessToken() === null && (state.url.indexOf('user/business/ViewDirectory') !== -1)) {
    //   this.route.navigate(['user/business/ViewDirectory']);
    // } else if (this.userInfo.getAccessToken() !== null && this.userInfo.getUserType() === 'ADMIN' && (state.url.indexOf('user/business/ViewDirectory') !== -1)) {
    //   this.route.navigate(['user/business/ViewDirectory']);
    // }
    return true;
  }
}


import { HttpClient } from '@angular/common/http';
import { PaginationParams } from './../../model/pagination';
import { FilterParam } from './../../model/filterParam';
import { Injectable } from '@angular/core';
import { CustomHttp } from '../../helperService/custom-http';
import { map } from '../../../../node_modules/rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MatrimonialService {

  constructor(private customHttp: CustomHttp, private Http: HttpClient) { }

  addMetrimonial(metrimonialInfoDetails, photo, bio): any {
    return this.customHttp.postFormDataNew('/api/Matrimonial/AddMatrimonial', metrimonialInfoDetails, photo, bio).pipe(map((response) => {
      return response;
    }));
  }

  getMatrimonial(filterParam: FilterParam, paginationParams: PaginationParams): any {
    let url = '/api/matrimonial/GetMatrimonialList/';
    url = url + paginationParams.PageNo + '/' + paginationParams.PageSize;
    if (paginationParams.SortByField !== undefined && paginationParams.SortDirection !== undefined &&
      paginationParams.SortByField !== '' && paginationParams.SortDirection !== '' &&
      paginationParams.SortByField !== null && paginationParams.SortDirection !== null) {
      url = url + '/' + paginationParams.SortByField + '/' + paginationParams.SortDirection;
    }
    return this.customHttp.postFormData(url, '', null, filterParam).pipe(map((response) => {
      return response;
    }));
  }

  getMyMatrimonials(createdBy: string): any {
    return this.customHttp.postFormData('/api/matrimonial/GetMyMatrimonials', '', null, createdBy).pipe(map((response) => {
      return response;
    }));
  }

  GetMyMatrimonialDetailsByUserId(userId: number): any {
    return this.Http.get('/api/matrimonial/GetMyMatrimonialDetailsByUserId/' + userId).pipe(map((response) => {
      return response;
    }));
  }

  UpdateMyMatrimonial(myMatrimonialInfo, photo, bio): any {
    return this.customHttp.postFormDataNew('/api/Matrimonial/UpdateMatrimonial', myMatrimonialInfo, photo, bio).pipe(map((response) => {
      return response;
    }));
  }
  DeleteMyMatrimonial(Id: number): any {
    return this.customHttp.postFormDataNew('/api/Matrimonial/DeleteMyMatrimonial/' + Id).pipe(map((response) => {
      return response;
    }));
  }
  DownloadBioData(Id: number): any {
    return this.customHttp.get('/api/Matrimonial/DownloadBioData/' + Id).pipe(map((response) => {
     return response;
   }));
 }
}

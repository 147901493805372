import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Routes, RouterModule} from '@angular/router';
const route: Routes = [
  {path: 'admin', loadChildren: 'src/app/admin/admin.module#AdminModule'},
  {path: 'user', loadChildren: 'src/app/user/user.module#UserModule'},
  {path: '', redirectTo: 'user', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule, RouterModule.forRoot(route, {useHash: true})
  ],
  declarations: []
})
export class AppRoutingModule { }

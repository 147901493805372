import { Injectable } from '../../../node_modules/@angular/core';
import { appConfig } from '../app.config';

@Injectable()
export class UserInfo {
 private _Username: string;
 private _FirstName: string;
 private _LastName: string;
 private _RegistrationNo: string;
 private _RegistrationType: string;
 private _ImageUrl: string;
 private _UserId: string;
 private _access_token: string;
 private _UserType: string;
 private _CityName: string;
 private _email: string;
 private _CityId: string;
 private _DistrictId: string;
 private _StateId: string;

  constructor() {
    this._Username = null;
    this._FirstName = null;
    this._LastName = null;
    this._RegistrationNo = null;
    this._RegistrationType = null;
    this._UserId = null;
    this._access_token = null;
    this._UserType = null;
    this._CityName = null;
    this._email = null;
    this._CityId = null;
    this._DistrictId = null;
    this._StateId = null;
  }

  setStateId(StateId: string) {
    this._StateId = StateId;
  }
  getStateId() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._StateId = localStorage.getItem('StateId');
    } else {
      this._StateId = sessionStorage.getItem('StateId');
    }
    return this._StateId;
  }

  setDistrictId(DistrictId: string) {
    this._DistrictId = DistrictId;
  }
  getDistrictId() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._DistrictId = localStorage.getItem('DistrictId');
    } else {
      this._DistrictId = sessionStorage.getItem('DistrictId');
    }
    return this._DistrictId;
  }

  setCityId(CityId: string) {
    this._CityId = CityId;
  }
  getCityId() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._CityId = localStorage.getItem('CityId');
    } else {
      this._CityId = sessionStorage.getItem('CityId');
    }
    return this._CityId;
  }

  setEmail(email: string) {
    this._email = email;
  }
  getEmail() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._email = localStorage.getItem('Email');
    } else {
      this._email = sessionStorage.getItem('Email');
    }
    return this._email;
  }

  setUsername(Uname: string) {
    this._Username = Uname;
  }
  getUsername() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._Username = localStorage.getItem('Username');
    } else {
      this._Username = sessionStorage.getItem('Username');
    }
    return this._Username;
  }
  setFirstName(Fname: string) {
    this._FirstName = Fname;
  }

  getFirstName() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._FirstName = localStorage.getItem('FirstName');
    } else {
      this._FirstName = sessionStorage.getItem('FirstName');
    }
    return this._FirstName;
  }

  getCityName() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._CityName = localStorage.getItem('City');
    } else {
      this._CityName = sessionStorage.getItem('City');
    }
    return this._CityName;
  }

  setCityName(Cname: string) {
    this._CityName = Cname;
  }

  setLastName(Lname: string) {
    this._LastName = Lname;
  }

  getLastName() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._LastName = localStorage.getItem('LastName');
    } else {
      this._LastName = sessionStorage.getItem('LastName');
    }
    return this._LastName;
  }

  setRegistrationNo(RType: string) {
    this._RegistrationNo = RType;
  }

  getRegistrationNo() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._RegistrationNo = localStorage.getItem('RegistrationNo');
    } else {
      this._RegistrationNo = sessionStorage.getItem('RegistrationNo');
    }
    return this._RegistrationNo;
  }

  setRegistrationType(RType: string) {
    this._RegistrationType = RType;
  }

  getRegistrationType() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._RegistrationType = localStorage.getItem('RegistrationType');
    } else {
      this._RegistrationType = sessionStorage.getItem('RegistrationType');
    }
    return this._RegistrationType;
  }

  setImageUrl(imageUrl: string) {
    this._ImageUrl = imageUrl;
  }

  getImageUrl() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._ImageUrl = localStorage.getItem('ImageUrl');
    } else {
      this._ImageUrl = sessionStorage.getItem('ImageUrl');
    }
    return this._ImageUrl;
  }

  setUserId(UId: string) {
    this._UserId = UId;
  }

  getUserId() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._UserId = localStorage.getItem('UserId');
    } else {
      this._UserId = sessionStorage.getItem('UserId');
    }
    return this._UserId;
  }

  setAccessToken(aToken: string) {
    this._access_token = aToken;
  }

  getAccessToken() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._access_token = localStorage.getItem('access_token');
    } else {
      this._access_token = sessionStorage.getItem('access_token');
    }
    return this._access_token;
  }

  setUserType(uType: string) {
    this._UserType = uType;
  }

  getUserType(): any {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      this._UserType = localStorage.getItem('UserType');
    } else {
      this._UserType = sessionStorage.getItem('UserType');
    }
    return this._UserType;
  }

  setAllNull() {
    this._Username = null;
    this._FirstName = null;
    this._LastName = null;
    this._RegistrationNo = null;
    this._RegistrationType = null;
    this._UserId = null;
    this._access_token = null;
    this._UserType = null;
    this._CityName = null;
    this._email = null;
    this._CityId = null;
    this._StateId = null;
    this._DistrictId = null;
  }

  removeCurrentUser() {
    const isRemember = localStorage.getItem('IsRememberMe');
    if (isRemember === 'true') {
      localStorage.removeItem('Username');
      localStorage.removeItem('FirstName');
      localStorage.removeItem('LastName');
      localStorage.removeItem('RegistrationNo');
      localStorage.removeItem('RegistrationType');
      localStorage.removeItem('ImageUrl');
      localStorage.removeItem('UserId');
      localStorage.removeItem('UserType');
      localStorage.removeItem('access_token');
      localStorage.removeItem('Email');
      localStorage.removeItem('City');
      localStorage.removeItem('StateId');
      localStorage.removeItem('CityId');
      localStorage.removeItem('DistrictId');
    } else {
      sessionStorage.removeItem('Username');
      sessionStorage.removeItem('FirstName');
      sessionStorage.removeItem('LastName');
      sessionStorage.removeItem('RegistrationNo');
      sessionStorage.removeItem('RegistrationType');
      sessionStorage.removeItem('ImageUrl');
      sessionStorage.removeItem('UserId');
      sessionStorage.removeItem('UserType');
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('Email');
      sessionStorage.removeItem('City');
      sessionStorage.removeItem('StateId');
      sessionStorage.removeItem('CityId');
      sessionStorage.removeItem('DistrictId');
    }
  }

  setCurrentUser(currentUser: any, isRemember: any) {
    localStorage.setItem('IsRememberMe', isRemember.toString());
    if (isRemember === 'true') {
      localStorage.setItem('Username', currentUser.UserName);
      localStorage.setItem('FirstName', currentUser.FirstName);
      localStorage.setItem('LastName', currentUser.LastName);
      localStorage.setItem('RegistrationNo', currentUser.RegistrationNo);
      localStorage.setItem('RegistrationType', currentUser.RegistrationType);
      localStorage.setItem('ImageUrl', appConfig.apiUrl + currentUser.ImageUrl);
      localStorage.setItem('UserId', currentUser.UserId);
      localStorage.setItem('UserType', currentUser.UserType);
      localStorage.setItem('access_token', currentUser.access_token);
      localStorage.setItem('Email', currentUser.Email);
      localStorage.setItem('City', currentUser.City);
      localStorage.setItem('StateId', currentUser.StateId);
      localStorage.setItem('CityId', currentUser.CityId);
      localStorage.setItem('DistrictId', currentUser.DistrictId);
    } else {
      sessionStorage.setItem('Username', currentUser.UserName);
      sessionStorage.setItem('FirstName', currentUser.FirstName);
      sessionStorage.setItem('LastName', currentUser.LastName);
      sessionStorage.setItem('RegistrationNo', currentUser.RegistrationNo);
      sessionStorage.setItem('RegistrationType', currentUser.RegistrationType);
      sessionStorage.setItem('ImageUrl', appConfig.apiUrl + currentUser.ImageUrl);
      sessionStorage.setItem('UserId', currentUser.UserId);
      sessionStorage.setItem('UserType', currentUser.UserType);
      sessionStorage.setItem('access_token', currentUser.access_token);
      sessionStorage.setItem('Email', currentUser.Email);
      sessionStorage.setItem('City', currentUser.City);
      sessionStorage.setItem('StateId', currentUser.StateId);
      sessionStorage.setItem('CityId', currentUser.CityId);
      sessionStorage.setItem('DistrictId', currentUser.DistrictId);
    }
  }
}

import { UserInfoDetails } from 'src/app/model/userInfoDetail';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomHttp } from './helperService/custom-http';
import { UserInfo } from './model/userInfo';
import { ToastrModule } from 'ngx-toastr';
import { TokenService } from './interceptor/token.service';
import { FieldErrorDisplayModule } from './shared-module/field-error-display/field-error-display.module';
import { LoginGaurdService } from './guards/login-gaurd.service';
import { LoaderComponent } from './common/loader/loader.component';
import { LoaderService } from './common/services/loader.service';
import { MatrimonialListModule } from './user/matrimonial/matrimonial-list/matrimonial-list.module';



@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
    ],
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    FieldErrorDisplayModule,
    AppRoutingModule,
    RouterModule,
    HttpModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    MatrimonialListModule

  ],
  providers: [CustomHttp, UserInfo, UserInfoDetails, LoginGaurdService, LoaderService, {provide: HTTP_INTERCEPTORS, useClass: TokenService, multi: true}],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }


import { Injectable } from '@angular/core';
import { Http, Headers, Response, Request, BaseRequestOptions, RequestMethod, ResponseType, ResponseContentType, RequestOptions } from '@angular/http';
import { HttpRequest, HttpParams, HttpEvent, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { appConfig } from '../app.config';
import { UserInfo } from '../model/userInfo';
import { LoaderService } from './../common/services/loader.service';

@Injectable()
export class CustomHttp {

  constructor(private http: Http, private httpClient: HttpClient, private userInfo: UserInfo, private loaderService: LoaderService) { }

  downloadFile(fileUrl: string) {
    return this.http.get(fileUrl);
  }

  getToken(url: string, body: any = {}, header: any = {}) {
    url = appConfig.apiUrl + url;
    return this.request(url, RequestMethod.Post, body, header);
  }
  post(url: string, body: any = {}, header: any = {}) {
    url = appConfig.apiUrl + url;
    return this.request(url, RequestMethod.Post, body, header);
  }

  postFormDataAuthor(url: string, data?: any, filter: any = null): Observable<HttpEvent<any>> {
    this.loaderService.show();
    const formData = new FormData();
    if (filter !== undefined && filter !== null) {
      formData.append('filter', JSON.stringify(filter));
    }
    formData.append('form-data', JSON.stringify(data));
    const req = new HttpRequest('POST', url, formData);
    return this.httpClient.request(req).pipe(finalize(() => this.loaderService.hide()));
  }
  get(url: string) {
    url = appConfig.apiUrl + url;
    return this.request(url, RequestMethod.Get);
  }

  getWithHeader(url: string) {
    url = appConfig.apiUrl + url;
    const headerForToken = { 'Authorization': 'bearer ' + this.userInfo.getAccessToken() };
    return this.request(url, RequestMethod.Get, null, headerForToken);
  }

  getDirectUrl(url: string) {
    return this.request(url, RequestMethod.Get, null, null);
  }

  SubmitFormData(url: string, formData: FormData, filter: any = null): Observable<HttpEvent<any>> {
    this.loaderService.show();
    const request = new HttpRequest('POST', url, formData, {
      headers: new HttpHeaders({
        'Content-Type': 'application.json'
      })
    });
    return this.httpClient.request(request).pipe(finalize(() => this.loaderService.hide()));
  }

  postFormData(url: string, data?: any, file?: File, filter: any = null): Observable<HttpEvent<any>> {
    this.loaderService.show();
    const formData = new FormData();
    if (file !== undefined && file !== null) {
      formData.append('image', file);
    }
    if (filter !== undefined && filter !== null) {
      formData.append('filter', JSON.stringify(filter));
    }
    formData.append('form-data', JSON.stringify(data));
    const req = new HttpRequest('POST', url, formData);
    return this.httpClient.request(req).pipe(finalize(() => this.loaderService.hide()));
  }


  postFormDataNew(url: string, data?: any, file?: File, file2?: File, filter: any = null): Observable<HttpEvent<any>> {
    this.loaderService.show();
    const formData = new FormData();
    if (file !== undefined && file !== null) {
      formData.append('image', file);
    }
    if (file2 !== undefined && file2 !== null) {
      formData.append('biodata', file2);
    }
    if (filter !== undefined && filter !== null) {
      formData.append('filter', JSON.stringify(filter));
    }
    formData.append('form-data', JSON.stringify(data));
    const req = new HttpRequest('POST', url, formData);
    return this.httpClient.request(req).pipe(finalize(() => this.loaderService.hide()));
  }

  postFormDataList(url: string, data?: any, formData?: FormData): Observable<HttpEvent<any>> {
    this.loaderService.show();
    // const formData = new FormData();
    // if (file !== undefined && file !== null) {
    //   formData.append('image', file);
    // }
    formData.append('form-data', JSON.stringify(data));
    const req = new HttpRequest('POST', url, formData);
    return this.httpClient.request(req).pipe(finalize(() => this.loaderService.hide()));
  }


  private request(url: string, method: RequestMethod, body?: any, header?: any, isBlob = false): Observable<Response> {
    this.loaderService.show();
    const options = new BaseRequestOptions();
    options.url = url;
    options.method = method;
    options.body = body;
    if (header !== undefined) {
      options.headers = new Headers(header);
    }
    const request = new Request(options);
    return this.http.request(request).pipe(catchError((error: any) => this.onErrorHandler(error)), finalize(() => this.loaderService.hide()));
  }

  private onErrorHandler(error: any) {
    const errors = error.json();
    if (error.status === 406 && Array.isArray(errors) && errors.indexOf('User is not logged in.') !== -1) {
    } else if (error.status === 401 && Array.isArray(errors) && errors.indexOf('CSRF validation failed') !== -1) {
      // TODO: should logout after getting token
    }
    return throwError(errors);
  }
}

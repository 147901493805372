import { LoaderService } from './../services/loader.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  show = false;
  private subscription: Subscription;

  constructor( private loaderService: LoaderService) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderSubject.subscribe( (visible: boolean) => {
      this.show = visible;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
}

}

import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '../../../node_modules/@angular/common/http';
import { appConfig } from '../app.config';
import { Observable, throwError } from '../../../node_modules/rxjs';
import { UserInfo } from '../model/userInfo';
import { Router } from '@angular/router';

@Injectable()
export class TokenService implements HttpInterceptor {

  constructor(private userInfo: UserInfo, private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerObj = {};
    headerObj['Authorization'] = 'Bearer ' + this.userInfo.getAccessToken();
    const headers = new HttpHeaders(headerObj);
    const authReq = req.clone({ url: appConfig.apiUrl + req.url, headers: headers });
    return next.handle(authReq)
      .pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              let navUrl = 'user/login';
              if (this.userInfo.getUserType() === 'ADMIN') {
                navUrl = 'admin';
              }
              this.userInfo.setAllNull();
              this.userInfo.removeCurrentUser();
              this.router.navigate([navUrl, {SessionTimedOut: true}]);
              return throwError('Session has been expired. Please login again');
            } else {
              return throwError(err);
            }
          }
        })
      );
  }
}

export class PaginationParams {
  PageNo: number;
  PageSize: number;
  PageCount: number;
  CurrentRecordCount: number;
  TotalRecordCount: number;
  SortByField: string;
  SortDirection: string;

  constructor() {
    this.PageNo = 1;
    this.PageSize = 10;
    this.SortByField = 'CreatedOn';
    this.SortDirection = 'desc';
    this.PageCount = 1;
    this.TotalRecordCount = 0;
    this.CurrentRecordCount = 0;
  }
}

import { HttpClient } from '@angular/common/http';
import { FilterParam } from './../model/filterParam';
import { CustomHttp } from './custom-http';
import { Injectable } from '@angular/core';
import { map } from '../../../node_modules/rxjs/operators';
import { PaginationParams } from '../model/pagination';
import { Response, Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private customHttp: CustomHttp, private httpClient: HttpClient) { }

  commonCommunicationData: any;

  checkMobileNoIsExist(mobileNo: string): any {
    return this.httpClient.get('/api/Member/CheckMobileNo/' + mobileNo).pipe(map((response) => {
       return response;
     }));
   }

  getCurrentCommunicationData(): any {
    return this.commonCommunicationData;
  }

  setCurrentCommunicationData(data: any) {
    this.commonCommunicationData = data;
  }

  getdownloadFile(fileUrl): any {
    return this.customHttp.downloadFile(fileUrl).pipe(map((response) => {
      return this.downloadFile(response);
    }));
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  GetCountryList(): any {
    return this.customHttp.get('/api/Country').pipe(map((response) => {
      return response.json();
    }));
  }

  GetOccupationMainTypeList(): any {
    return this.customHttp.get('/api/OccupationType/OccupationMainTypes').pipe(map((response) => {
      return response.json();
    }));
  }

  GetOccupationSubTypeList(): any {
    return this.customHttp.get('/api/OccupationType/OccupationSubTypes').pipe(map((response) => {
      return response.json();
    }));
  }

  GetOccupationSubTypeByMainIdList(id): any {
    return this.customHttp.get('/api/OccupationType/OccupationSubTypesByMainId/' + id).pipe(map((response) => {
      return response.json();
    }));
  }

  GetStateList(countryId): any {
    return this.customHttp.get('/api/State/GetStatesByCountry/' + countryId).pipe(map((response) => {
      return response.json();
    }));
  }

  GetDistrictList(stateId): any {
    return this.customHttp.get('/api/District/GetDistrictsByState/' + stateId).pipe(map((response) => {
      return response.json();
    }));
  }

  GetCityList(districtId): any {
    return this.customHttp.get('/api/City/GetCityByDistrict/' + districtId).pipe(map((response) => {
      return response.json();
    }));
  }

  GetHouseTypeList(): any {
    return this.customHttp.get('/api/HouseType').pipe(map((response) => {
      return response.json();
    }));
  }

  GetUpdateByID(id): any {
    return this.customHttp.get('/api/updates/UpdatesById/' + id).pipe(map((response) => {
      return response.json();
    }));
  }

  GetUpdates(typeId, pageNo, pageSize): any {
    return this.customHttp.get('/api/updates/UpdatesByCategory/' + typeId + '/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetJainFoodRecipe(categoryId, pageNo, pageSize): any {
    return this.customHttp.get('/api/health/GetJainFoodRecipe/' + categoryId + '/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetPratinidhi(pageNo, pageSize): any {
    return this.customHttp.getWithHeader('/api/member/GetPratinidhi/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetPratinidhiByHouseType(type, pageNo, pageSize): any {
    return this.customHttp.get('/api/member/GetPratinidhiByHouseId/' + type + '/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetAllAlbums(pageNo, pageSize): any {
    return this.customHttp.get('/api/album/GetAllAlbums/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetGalleryImage(albumID, pageNo, pageSize): any {
    return this.customHttp.get('/api/member/GetGalleryImage/' + albumID + '/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetMediaCategory(): any {
    return this.customHttp.get('/api/GuruJiVideo/GetMediaCategory').pipe(map((res) => {
      return res.json();
    }));
  }

  GetMediaByCategory(categoryId, pageNo, pageSize): any {
    return this.customHttp.get('/api/GuruJiVideo/Media/' + categoryId + '/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetMediaItems(mediaId, pageNo, pageSize): any {
    return this.customHttp.get('/api/GuruJiVideo/MediaItems/' + mediaId + '/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetAudioMediaItems(mediaId, pageNo, pageSize): any {
    return this.customHttp.get('/api/GuruJiVideo/AudioMediaItems/' + mediaId + '/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  GetLiteratureById(literatureId, pageNo, pageSize): any {
    return this.customHttp.get('/api/member/GetLiteratureById/' + literatureId + '/' + pageNo + '/' + pageSize).pipe(map((response) => {
      return response.json();
    }));
  }

  deleteLiteratureById(literatureId): any {
    return this.customHttp.get('/api/literature/DeleteLiterature/' + literatureId).pipe(map((response) => {
      return response.json();
    }));
  }

  getMembersList(filterParam: FilterParam, paginationParam: PaginationParams, isPendingList: boolean): any {
    let url = '/api/member/GetMembers/';
    if (isPendingList) {
      url = '/api/member/GetPendingMembers/';
    }
    url = url + paginationParam.PageNo + '/' + paginationParam.PageSize;
    if (paginationParam.SortByField !== undefined && paginationParam.SortDirection !== undefined &&
      paginationParam.SortByField !== '' && paginationParam.SortDirection !== '' &&
      paginationParam.SortByField !== null && paginationParam.SortDirection !== null) {
      url = url + '/' + paginationParam.SortByField + '/' + paginationParam.SortDirection;
    }
    return this.customHttp.postFormData(url, '', null, filterParam).pipe(map((response) => {
      return response;
    }));
  }

  getModulesList(): any {
    const url = '/api/member/GetModulesList';
    return this.customHttp.postFormData(url, '', null, null).pipe(map((response) => {
      return response;
    }));
  }

  GetCategoryList(): any {
    return this.customHttp.get('/api/Literature/ParentCategory').pipe(map((response) => {
      return response.json();
    }));
  }
  LoadMediaCategories(): any {
    return this.customHttp.get('/api/Media/GetMediaCategoryList').pipe(map((response) => {
      return response.json();
    }));
  }
  LoadMediaAlbum(mediaCategoryId: number) {
    return this.customHttp.get('/api/Media/GetMediaList/' + mediaCategoryId).pipe(map((response) => {
      return response.json();
    }));
  }
  getAllArtists(): any {
    return this.customHttp.get('/api/Media/GetMediaArtistsList').pipe(map((response) => {
      return response.json();
    }));
  }
  LoadMediaItem(paginationParam: PaginationParams): any {
    let url = '/api/Media/GetMediaItem/' + paginationParam.PageNo + '/' + paginationParam.PageSize;
    if (paginationParam.SortByField !== undefined && paginationParam.SortDirection !== undefined &&
      paginationParam.SortByField !== '' && paginationParam.SortDirection !== '' &&
      paginationParam.SortByField !== null && paginationParam.SortDirection !== null) {
      url = url + '/' + paginationParam.SortByField + '/' + paginationParam.SortDirection;
    }
    return this.customHttp.get(url).pipe(map((response) => {
      return response.json();
    }));
  }
  DeleteBusinessProfile(param: string): any {
    return this.customHttp.get('/api/OccupationType/DeleteBusinessProfile/' + param).pipe(map((response) => {
      return response;
    }));
  }

  DeleteAlbum(Id): any {
    const url = '/api/Album/DeleteAlbum/' + Id;
    return this.customHttp.get(url).pipe(map((response) => {
      return response;
    }));
  }

  DeleteImage(Id): any {
    const url = '/api/Album/DeleteImage/' + Id;
    return this.customHttp.get(url).pipe(map((response) => {
      return response;
    }));
  }

  getHelpList(filterParam: FilterParam, paginationParam: PaginationParams): any {
    let url = '/api/help/GetHelpMembersByOccupation/';
    url = url + paginationParam.PageNo + '/' + paginationParam.PageSize;
    if (paginationParam.SortByField !== undefined && paginationParam.SortDirection !== undefined &&
      paginationParam.SortByField !== '' && paginationParam.SortDirection !== '' &&
      paginationParam.SortByField !== null && paginationParam.SortDirection !== null) {
      url = url + '/' + paginationParam.SortByField + '/' + paginationParam.SortDirection;
    }
    return this.customHttp.postFormData(url, '', null, filterParam).pipe(map((response) => {
      return response;
    }));
  }
  getHelpTimeFrame(helpId: number): any {
    const url = '/api/help/GetHelpTimeFrame/' + helpId;
    return this.customHttp.postFormData(url).pipe(map((response) => {
      return response;
    }));
  }

  ViewMemberForm(memberId: number): any {
    return this.customHttp.get('/api/member/ViewMemberForm/' + memberId).pipe(map((response) => {
     return response;
   }));
 }
}

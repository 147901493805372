export class FilterParam {
 CountryId: number;
 StateId: number;
 DistrictId: number;
 CityId: number;
 RegistrationType: number;
 Status: number;
 BloodGroup: number;
 OccupationTypeId: number;
 OccupationMainTypeId: number;
 OccupationSubTypeId: number;
 HouseTypeId: number;
 SearchKey: string;
 AgeGroup: string;
 Gender: number;
 IsPremiumMember: number;
 Gotra: string;
 Manglik: number;
 MaritalStatus: number;
 Occupation: string;
 Working: number;
 Qualification: number;
 JainType: number;
 Complexion: number;
 Mobile: string;
 StartDate: any;
 DateStartType: any;
 EndDate: any;
 DateEndType: any;
 IsPatrikaSubscriber: boolean;
 FirmName: string;
 FirmDomain: string;
 FirmContactNo: string;
 FirmAddress: string;
 Designation: string;
 ServiceDetail: string;
 Speciality: string;
 IsActive: boolean;
 ReqMobileNumber: string;
 MemberForm: number;

 constructor() {
  this.SearchKey = '';
  this.CountryId = 0;
  this.StateId = 0;
  this.DistrictId = 0;
  this.CityId = 0;
  this.RegistrationType = 0;
  this.BloodGroup = 0;
  this.OccupationTypeId = 0;
  this.OccupationMainTypeId = 0;
  this.OccupationSubTypeId = 0;
  this.Status = 1;
  this.AgeGroup = '0-1000';
  this.HouseTypeId = 0;
  this.Gender = 0;
  this.IsPremiumMember = 1;
  this.Gotra = '';
  this.Manglik = 0;
  this.MaritalStatus = 0;
  this.Occupation = '';
  this.Working = 0;
  this.Qualification = 0;
  this.JainType = 0;
  this.Complexion = 0;
  this.Mobile = '';
  this.StartDate = '';
  this.EndDate = '';
  this.IsPatrikaSubscriber = false;
  this.FirmName = '';
  this.FirmDomain = '';
  this.FirmContactNo = '';
  this.FirmAddress = '';
  this.Designation = '';
  this.ServiceDetail = '';
  this.Speciality = '';
  this.IsActive = true;
  this.ReqMobileNumber = '';
  this.MemberForm = 0;
 }
}

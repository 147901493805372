import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatrimonialListComponent } from './matrimonial-list.component';

const routes: Routes = [
  {
    path: '', component: MatrimonialListComponent, children: [
      { path: 'user/matrimonialList', loadChildren: '../matrimonial-list/matrimonial-list.module#MatrimonialListModule' }]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MatrimonialListRoutingModule { }

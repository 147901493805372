export class UserInfoDetails {
  MemberId: number;
  Username: string;
  FirstName: string;
  LastName: string;
  FatherName: string;
  MotherName: string;
  MobileNo: string;
  DateOfBirth: string;
  DOBDateType: any;
  Gender: number;
  GenderText: string;
  MaritalStatus: number;
  MaritalStatusText: string;
  Gotra: string;
  BloodGroup: number;
  BloodGroupText: string;
  CountryId: number;
  CountryName: string;
  CountryNameInHindi: string;
  StateId: number;
  StateName: string;
  StateNameInHindi: string;
  DistrictId: number;
  DistrictName: string;
  DistrictNameInHindi: string;
  CityId: number;
  CityName: string;
  CityNameInHindi: string;
  Pincode: string;
  Address: string;
  Email: string = null;
  WeddingDateType: any;
  WeddingDate: string;
  HouseTypeId: number;
  HouseTypeName: string;
  ImageUrl: string;
  RegistrationType: number;
  RegistrationTypeName: string;
  OccupationTypeId: number;
  OccupationTypeName: string;
  BusinessDetail: string;
  Status: number;
  Comments: string;
  CreatedBy: number;
  UpdatedBy: number;
  RegistrationNo: string = null;
  selected: boolean;
  IswebUser: boolean;
  SpouseName: string;
  IsPremiumMember: number;
  AreaLocality: string;
  AnswerDate: any;
  Winner: string;
  Year: string;
  Month: string;
  IsPatrikaSubscriber: boolean;
  FileAttachment: string;

  constructor() {
    const d: Date = new Date();
    this.MemberId = 0;
    this.Gender = 1;
    this.MaritalStatus = 1;
    this.Status = 1;
    this.RegistrationType = 1;
    this.BloodGroup = 0;
    this.CountryId = 0;
    this.StateId = 0;
    this.DistrictId = 0;
    this.CityId = 0;
    this.HouseTypeId = 0;
    this.OccupationTypeId = 0;
    this.IswebUser = true;
    this.Address = '';
    this.IsPremiumMember = 1;
    this.AreaLocality = '';
    this.IsPatrikaSubscriber = false;
    this.FileAttachment = '';
  }
}

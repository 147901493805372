import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public loaderSubject = new Subject();
  constructor() { }

  show(): any {
    this.loaderSubject.next(true);
}

hide(): any {
    this.loaderSubject.next(false);
}
}

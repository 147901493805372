import { UserInfo } from 'src/app/model/userInfo';
import { MatrimonialInfo } from './../../../model/matrimonial';
import { CommonService } from './../../../helperService/common.service';
import { PaginationParams } from './../../../model/pagination';
import { MatrimonialService } from './../matrimonial.service';
import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { appConfig } from 'src/app/app.config';
// import { PaginationParams } from 'src/app/model/pagination';
import { FilterParam } from 'src/app/model/filterParam';

@Component({
  selector: 'app-matrimonial-list',
  templateUrl: './matrimonial-list.component.html',
  styleUrls: ['./matrimonial-list.component.css']
})
export class MatrimonialListComponent implements OnInit {
  member: any = [];
  matrimonial: any = [];
  matrimonialList: any = [];
  paginationParams: PaginationParams = new PaginationParams();
  filterParam: FilterParam = new FilterParam();
  public districts: any = [];
  public states: any = [];
  bioDataUrl: any;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  constructor(private matrimonialserv: MatrimonialService, private _commonService: CommonService, private userInfo: UserInfo) {
    this.paginationParams = new PaginationParams();
    this.paginationParams.SortByField = 'UpdatedOn';
    this.paginationParams.SortDirection = 'desc';
  }
  ngOnInit() {
    this.LoadMatrimonialList();
  }
  apiUrl() {
    return appConfig.apiUrl;
  }
  LoadMatrimonialList() {
    this.filterParam.Status = 1;
    this.matrimonialserv.getMatrimonial(this.filterParam, this.paginationParams).subscribe(res => {
      if (res.status === 200) {
        if (res.Message === undefined) {
          this.matrimonialList = res.body.Data;
          this.paginationParams = res.body.Paging;
          // this.matrimonial = JSON.parse(res._body);
          // this.matrimonialList = this.matrimonial.Data;
          // this.paginationParams = this.matrimonial.Data.Paging;
        }
      }
    });
  }
  DownloadBioData(Id: number) {
    this.matrimonialserv.DownloadBioData(Id).subscribe(res => {
      this.bioDataUrl = res.json();
      const link = this.downloadLink.nativeElement;
      link.href = this.apiUrl() + this.bioDataUrl;
      link.download = 'Bio Data';
      document.body.appendChild(link);
      link.style = 'display: none';
      link.click();
      link.remove();
    });
  }
  ApplyFilter() {
    this.ResetPagination();
    this.LoadMatrimonialList();
  }
  ClearFilter() {
    this.filterParam = new FilterParam();
    this.ResetPagination();
    this.LoadMatrimonialList();
  }
  ResetPagination() {
    this.paginationParams = new PaginationParams();
    this.paginationParams.SortByField = 'UpdatedOn';
    this.paginationParams.SortDirection = 'desc';
  }
  pageChanged(event: any): void {
    this.paginationParams.PageNo = event.page;
    this.LoadMatrimonialList();
  }
  ShowBrideGroomFullDetails(member) {
    this.member = member;
  }
}

import { PaginationModule } from 'ngx-bootstrap';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatrimonialListComponent } from './matrimonial-list.component';
import { MatrimonialListRoutingModule } from './matrimonial-list-routing.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    PaginationModule.forRoot(),
    FormsModule,
    MatrimonialListRoutingModule
  ],
  declarations: [MatrimonialListComponent]
})
export class MatrimonialListModule { }
